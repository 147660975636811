import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-accessibility-menu',
  templateUrl: './accessibility-menu.component.html',
})
export class AccessibilityMenuComponent implements OnInit, OnChanges {
  openMenu = false;
  isDragging = false;
  invertColorsActive = false;
  @Output() isTextToSpeechActive = new EventEmitter(false);
  @Input() selectedText: string;
  private speechSynthesis: SpeechSynthesis;
  private utterance: SpeechSynthesisUtterance;
  private voices: SpeechSynthesisVoice[] = [];

  fontSize = 16;
  constructor(private renderer: Renderer2) {
    this.speechSynthesis = window.speechSynthesis;
    this.utterance = new SpeechSynthesisUtterance();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedText && changes.selectedText.currentValue) {
      this.speak(this.selectedText);
    }
  }

  increaseFontSize(): void {
    if (this.fontSize == 16) {
      this.fontSize += 4;
    } else {
      this.fontSize += 2;
    }

    document.documentElement.style.fontSize = `${this.fontSize}px`;
  }

  decreaseFontSize(): void {
    if (this.fontSize > 8) {
      this.fontSize -= 2;
      document.documentElement.style.fontSize = `${this.fontSize}px`;
    }
  }

  textToSpeech(e) {
    e.preventDefault();
    this.isTextToSpeechActive.emit(true);
  }

  speak(text: string): void {
    if (this.speechSynthesis.speaking) {
      this.speechSynthesis.cancel(); // Stop any ongoing speech
    }
    this.utterance.text = text;
    this.utterance.rate = 1; // Set the speech rate (1 is normal)
    this.utterance.pitch = 1; // Set the speech pitch (1 is normal)
    this.utterance.lang = localStorage.getItem('language') === 'ar' ? 'ar-SA' : 'en-US';
    this.speechSynthesis.speak(this.utterance);
  }

  stop(): void {
    if (this.speechSynthesis.speaking) {
      this.speechSynthesis.cancel(); // Stop any ongoing speech
    }
  }

  invertColors() {
    this.invertColorsActive = true;
    document.documentElement.style.filter = 'invert(1)';
  }

  reset() {
    this.invertColorsActive = false;
    this.isTextToSpeechActive.emit(false);
    this.fontSize = 16;
    document.documentElement.style.removeProperty('font-size');
    document.documentElement.style.removeProperty('filter');
    this.stop();
  }

  onDragStarted() {
    this.isDragging = true;
  }
}
