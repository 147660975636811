import { Component, OnInit, NgZone, HostListener } from '@angular/core';
import { TranslationService } from './services/translation.service';
import { TenantService } from './services/tenant.service';
import * as accessibility from 'accessibility';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  isTextToSpeechActive = false;
  selectedText = '';
  speechSynthesis: SpeechSynthesis;
  utterance: SpeechSynthesisUtterance;
  voices: SpeechSynthesisVoice[] = [];
  constructor(
    private translateService: TranslationService,
    private tenantService: TenantService
  ) {
    this.speechSynthesis = window.speechSynthesis;
    this.utterance = new SpeechSynthesisUtterance();
    this.getTenant();
  }

  ngOnInit() {
    this.setLnag();
    let language = localStorage.getItem('language') || 'en-US';
    language = language === 'ar' ? 'ar-SA' : 'en-US';
    let labels = {
      resetTitle: 'reset (in my language)',
      closeTitle: 'close (in my language)',
      menuTitle: 'title (in my language)',
      increaseText: 'increase text size (in my language)',
      decreaseText: 'decrease text size (in my language)',
      increaseTextSpacing: 'increase text spacing (in my language)',
      decreaseTextSpacing: 'decrease text spacing (in my language)',
      increaseLineHeight: 'increase line height (in my language)',
      decreaseLineHeight: 'decrease line height (in my language)',
      invertColors: 'invert colors (in my language)',
      grayHues: 'gray hues (in my language)',
      underlineLinks: 'underline links (in my language)',
      bigCursor: 'big cursor (in my language)',
      readingGuide: 'reading guide (in my language)',
      textToSpeech: 'text to speech (in my language)',
      speechToText: 'speech to text (in my language)',
      disableAnimations: 'Disable Animations',
      hotkeyPrefix: 'Hotkey Prefix',
    };
    let modules: accessibility.IAccessibilityModulesOptions = {
      decreaseText: true,
      increaseText: true,
      invertColors: true,
      increaseTextSpacing: false,
      decreaseTextSpacing: false,
      increaseLineHeight: false,
      decreaseLineHeight: false,
      grayHues: false,
      underlineLinks: false,
      bigCursor: false,
      readingGuide: false,
      textToSpeech: false,
      speechToText: false,
      disableAnimations: false,
    };
    console.log(language);

    let options: accessibility.IAccessibilityOptions = {
      // labels: labels,
      modules: modules,
      language: {
        textToSpeechLang: language,
        speechToTextLang: language,
      },
    };
    options.textToSpeechLang = language; // or any other language
    options.speechToTextLang = language; // or any other language
    var instance = new accessibility.Accessibility(options);
    // instance.menuInterface.textToSpeech();
    // instance.menuInterface.speechToText();
  }

  setLnag() {
    const lang = this.translateService.getSelectedLanguage();
    this.translateService.setLanguage(lang);
  }

  getTenant() {
    this.tenantService.getTenant().subscribe(
      (data: any) => {
        this.tenantService.defaultTenantSubject.next(data);
      },
      (err) => {
        console.error(err);
      }
    );
  }
  removeTags(str) {
    if (str === null || str === '') {
      return false;
    } else {
      str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, '');
    }
  }

  textToSpeech(e) {
    e.preventDefault();
    this.isTextToSpeechActive = !this.isTextToSpeechActive;
    if (!this.isTextToSpeechActive) {
      this.stop();
    }
  }

  speak(text: string): void {
    if (this.speechSynthesis.speaking) {
      this.speechSynthesis.cancel(); // Stop any ongoing speech
    }
    this.utterance.text = text;
    this.utterance.rate = 1; // Set the speech rate (1 is normal)
    this.utterance.pitch = 1; // Set the speech pitch (1 is normal)
    this.utterance.lang =
      localStorage.getItem('language') === 'ar' ? 'ar-SA' : 'en-US';
    this.speechSynthesis.speak(this.utterance);
  }

  stop(): void {
    if (this.speechSynthesis.speaking) {
      this.speechSynthesis.cancel(); // Stop any ongoing speech
    }
  }
  // onDragStarted () {
  //   this.isTextToSpeechActive = false;
  // }
  // onDragEnded () {
  //   this.isTextToSpeechActive = false;
  // }

  @HostListener('document:click', ['$event']) onClick(e) {
    if (e && this.isTextToSpeechActive) {
      console.log(e);
      console.log(e.target.innerText);
      if (e.target.innerText) {
        this.speak(e.target.innerText);
      }
    }
  }
}
