import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
  FormGroup,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { QuizQuestionType } from 'src/app/models/trainer/training-courses-list/enums/QuizQuestionType';
import {
  ModuleDetail,
  ModuleLessonDetail,
} from 'src/app/models/trainer/training-course-details/CourseDetails';
import { ContentType } from 'src/app/models/trainer/training-courses-list/enums/ContentType';
import { SwalService } from 'src/app/services/swal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LookupsService } from 'src/app/services/lookups.service';
import { Lookup } from 'src/app/models/lookups/Lookup';
import { CustomValidator } from 'src/app/helpers/custom.validator';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { QuestionBankService } from 'src/app/services/question-bank.service';
import { RespondentMessageComponent } from '../respondent-message/respondent-message.component';

@Component({
  selector: 'app-questions-form',
  templateUrl: './questions-form.component.html',
})
export class QuestionsFormComponent implements OnInit, OnChanges {
  @Output() QuestionSaved = new EventEmitter<any>();
  @Output() DeleteQuestion = new EventEmitter<any>();
  @Output() SortChanged = new EventEmitter<any>();
  @Output() loadingEvent = new EventEmitter<boolean>();
  @Input() courseId: any;
  @Input() lessonId: any;
  @Input() moduleId: any;
  @Input() order;
  @Input() selectedType;
  @Input() isSlideQuiz = false;
  @Input() isAutomatically = false;
  @Input() slideQuizData: any;
  @Input() assessmentType: any;
  @Input() moduleDetails: ModuleDetail;
  @Input() isQuestionBank = false;
  @Input() isAssessment = false;
  @Input() RefreshQuestionList: number;
  @Input() _formGroup: FormGroup;
  @Input() QuestionsList: any[];
  @Input() isTraditionalAssessment: false;
  @Input() AllowLinkQuestions: boolean = false;

  isValid = false;
  @Input() isSubmitted = false;
  dragging = false;
  editMode = false;

  activeIndex: any = 0;
  QuizQuestionType = QuizQuestionType;
  ContentType = ContentType;
  @Input() moduleLessonDetail: ModuleLessonDetail;
  listAllQuizQuestionType: Lookup[] = [];
  isExpandQustion: boolean[] = [];

  // Tags
  alltags: Lookup[] = [];
  allCategories: Lookup[] = [];
  allLevels: Lookup[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allowedExt = ['jpeg', 'jpg', 'png', 'pdf'];
  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private swalService: SwalService,
    private lookupsService: LookupsService,
    private questionBankService: QuestionBankService,
    private activatedRoute: ActivatedRoute,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
    // console.log(this.QuestionsList);

    this.questionsArray.setValidators(this.validateControls());
    this.getAllContentTypes();

    if (this.isQuestionBank) {
      this.getCategoryLookUp();
      this.getDefficultyLookUp();
      this.getMyTagsLookUp();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.isQuestionBank &&
      changes.RefreshQuestionList &&
      this.RefreshQuestionList
    ) {
      this.isExpandQustion = [];
      this.questionsArray.clear();
      this.getQuestionsByQuestionBankId();
    }
    // debugger
    if (
      this.isAssessment
      && changes.QuestionsList
      && this.QuestionsList
      && this.QuestionsList.length
      && this.listAllQuizQuestionType
      && this.listAllQuizQuestionType.length
      // && this.questionsArray
      // && this.questionsArray.length
    ) {
      this.bindManually();
    }
  }

  bindManually() {
    this.bindQuestionsData(this.QuestionsList);
    // for (let index = this.questionsArray.length - 1; index >= this.questionsArray.length - 1 - this.QuestionsList.length; index--) {
    //   this.isExpandQustion[index] = true;
    // }
  }

  getQuestionsByQuestionBankId() {
    let QuestionBankID: string;
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params.get('id')) {
        QuestionBankID = params.get('id');
      }
    });
    this.loadingEvent.emit(true);
    this.questionBankService.getQuestions(QuestionBankID).subscribe(
      (data: any) => {
        this.loadingEvent.emit(false);
        if (data && data.pagedData?.length) {
          this.bindQuestionsData(data.pagedData);
        } else if (data && data.pagedData?.length == 0) {
          this.isExpandQustion[0] = true;
          this.addQuestion();
        }
      },
      (error) => {
        this.loadingEvent.emit(false);
      }
    );
  }

  getAllContentTypes() {
    this.lookupsService.getAllQuizQuestionType().subscribe(
      (data) => {
        this.listAllQuizQuestionType = data as Lookup[];
        if (this.isSlideQuiz) {
          if (
            this.slideQuizData &&
            this.slideQuizData.quizModuleLesson &&
            this.slideQuizData.quizModuleLesson.addQuizQuestionDtos &&
            this.slideQuizData.quizModuleLesson.addQuizQuestionDtos.length
          ) {
            this.bindDataQuiz();
          }
          if (this.lessonId) {
            this.editMode = true;
            //this.getModuleLessonById();
          }
          if (!this.slideQuizData.quizModuleLesson) {
            this.isExpandQustion[0] = true;
            this.addQuestion();
          }
        } else {
          if (this.lessonId) {
            this.editMode = true;
            this.bindData();
          }
          if (this.isQuestionBank) {
            this.getQuestionsByQuestionBankId();
          }
          if (this.isAssessment && !this.QuestionsList?.length) {
            this.isExpandQustion[0] = true;
            this.addQuestion();
          } else if (
            this.isAssessment &&
            this.QuestionsList &&
            this.QuestionsList.length
          ) {
            this.bindManually();
          }
        }
      },
      (error) => { }
    );
  }

  getDefficultyLookUp() {
    this.lookupsService.getQuestionBankDifficulties().subscribe(
      (data) => {
        this.allLevels = data as Lookup[];
      },
      (error) => { }
    );
  }

  getCategoryLookUp() {
    this.lookupsService.getQuestionBankCategories().subscribe(
      (data) => {
        this.allCategories = data as Lookup[];
      },
      (error) => { }
    );
  }

  getMyTagsLookUp() {
    this.lookupsService.getListMyTags().subscribe(
      (data) => {
        this.alltags = data as Lookup[];
      },
      (error) => { }
    );
  }

  getQustionTypeId(id) {
    if (this.listAllQuizQuestionType && this.listAllQuizQuestionType.length) {
      this.listAllQuizQuestionType.forEach((element) => {
        if (element.id == id) {
          return element;
        }
      });
    }
  }

  bindDataQuiz() {
    let obj = this.slideQuizData.quizModuleLesson;
    this.f.title.setValue(obj.addQuizModuleLesson.title);
    this.f.percent.setValue(obj.addQuizModuleLesson.passPercentage);
    this.f.isLimitnumberOfQuiz.setValue(
      obj.addQuizModuleLesson.isLimitNumberOfRetake
    );
    this.f.numberOfQuiz.setValue(obj.addQuizModuleLesson.maxNumberOfRetake);

    obj.addQuizQuestionDtos.forEach((ele: any, index) => {
      //this.isExpandQustion[index] = true;
      this.addQuestion(ele);
    });
  }

  bindData() {
    this.f.title.setValue(this.moduleLessonDetail.title);
    this.f.percent.setValue(this.moduleLessonDetail.passPercentage);
    this.f.isLimitnumberOfQuiz.setValue(
      this.moduleLessonDetail.isLimitNumberOfRetake
    );
    this.f.numberOfQuiz.setValue(this.moduleLessonDetail.maxNumberOfRetake);
    this.moduleLessonDetail.qiuzQuestions.forEach((ele: any, index) => {
      //this.isExpandQustion[index] = true;
      this.addQuestion(ele);
    });
  }

  bindQuestionsData(list: any) {
    list.forEach((ele: any, index) => {
      this.addQuestion(ele);
    });
  }
  get f() {
    return this._formGroup.controls;
  }

  // questionsArray
  get questionsArray(): FormArray {
    return this._formGroup.controls.questionsArray as FormArray;
  }

  newQuestion($el: any = null): FormGroup {
    console.log($el)
    return this.formBuilder.group({
      id: [$el ? $el.id : null],
      isFromQuestionBank: [$el && $el?.isFromQuestionBank ? $el?.isFromQuestionBank : false],
      questionBankQuestionId: [$el && $el?.questionBankQuestionId ? $el?.questionBankQuestionId : null],
      questionType: [
        $el
          ? this.listAllQuizQuestionType.find(
            (item) =>
              item.id ===
              ($el.questionType && $el.questionType.id
                ? $el.questionType.id
                : $el.questionTypeId)
          )
          : this.listAllQuizQuestionType.find(
            (item) => item.id === QuizQuestionType.MULTIPLECHOICE
          ),
        [Validators.required],
      ],
      questionText: [
        $el ? $el.question : null,
        [
          Validators.required,
          Validators.maxLength(500),
          CustomValidator.noWhiteSpace,
        ],
      ],
      totalPoints: [
        $el ? $el.totalScore : null,
        !this.isQuestionBank ? Validators.min(1) : null,
      ],
      penalty: [$el ? $el.isPenalty : false],
      order: [
        this.isTraditionalAssessment
          ? this.questionsArray.length
          : $el
            ? $el.order
            : 0,
      ],
      QuestionBankDifficultyId: [$el ? $el.questionBankDifficultyId : null],
      QuestionBankCategoryId: [$el ? $el.questionBankCategoryId : null],
      QuestionTags: [$el ? $el.questionTags : []],
      files: [$el ? $el.attachments : []],
      deteledFiles: [[]],
      options: this.formBuilder.array([]),
      RandomizingLinkedOrder: [$el ? $el.randomizingLinkedOrder : null],
    });
  }

  public addQuestion($el: any = null) {
    this.questionsArray.push(this.newQuestion($el));

    if ($el && (!this.isQuestionBank || this.isAssessment)) {
      $el.quizQuestionsAnswers.forEach((element, index) => {
        this.addQuestionValue(this.questionsArray.length - 1, element);
      });
    } else if ($el && this.isQuestionBank) {
      $el.questionBankQuestionAnswer.forEach((element, index) => {
        this.addQuestionValue(this.questionsArray.length - 1, element);
      });
    } else {
      this.addQuestionValue(this.questionsArray.length - 1);
    }
    this.activeIndex = this.questionsArray.length - 1;
  }

  removeQuestion($index: number) {
    if (this.isQuestionBank || this.isAssessment) {
      this.DeleteQuestion.emit({
        index: $index,
        id: this.questionsArray.at($index).value.id,
      });
    } else {
      this.questionsArray.removeAt($index);
    }
  }

  public questionvalues(index: number): FormArray {
    return this.questionsArray.at(index).get('options') as FormArray;
  }

  optionControl($el: any = null, questionIndex = null): FormGroup {
    return this.formBuilder.group({
      id: [$el ? $el.id : null],
      text: [
        $el ? $el.answer : null,
        [Validators.maxLength(250), CustomValidator.noWhiteSpace],
      ],
      scoringPoints: [$el ? $el.scoringPoint : 0],
      isCorrectAnswer: [$el ? $el.isCorrect : false],
      note: [$el ? $el.respondentMessage : null],
      order: [$el ? $el.order : 0],
    });
  }

  addQuestionValue(questionIndex: number, $el: any = null) {
    if (!$el && !this.questionvalues(questionIndex).length) {
      for (let index = 0; index < 4; index++) {
        this.questionvalues(questionIndex).push(
          this.optionControl($el, questionIndex)
        );
      }
    } else {
      this.questionvalues(questionIndex).push(
        this.optionControl($el, questionIndex)
      );
    }
  }

  removeQuestionValue(questionIndex: number, optionIndex: number) {
    this.questionvalues(questionIndex).removeAt(optionIndex);
  }

  addNote(questionIndex: number, optionIndex: number) {
    if (
      !this.isAutomatically ||
      this.getOption(questionIndex, optionIndex, 'note').value
    ) {
      let data = {
        question: this.getQuestion(questionIndex, 'questionText').value,
        option: this.getOption(questionIndex, optionIndex, 'text').value,
        note: this.getOption(questionIndex, optionIndex, 'note').value,
        isAutomatically: this.isAutomatically,
      };
      const dialogRef = this.dialog.open(RespondentMessageComponent, {
        width: '550px',
        data,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getOption(questionIndex, optionIndex, 'note').setValue(result);
        }
      });
    }
  }

  getQuestion(questionIndex, controlName) {
    return this.questionsArray.controls[questionIndex].get(controlName);
  }

  getOption(questionIndex, optionIndex, controlName) {
    return this.questionvalues(questionIndex).controls[optionIndex].get(
      controlName
    );
  }

  calcPoints(questionIndex, optionIndex) {
    if (
      this.getQuestion(questionIndex, 'questionType').value?.id ==
      QuizQuestionType.CKECKBOX
    ) {
      let correctAnswerTotal: any = 0;
      let unCorrectAnswerTotal: any = 0;
      const list = this.questionvalues(questionIndex).value;
      list.forEach((ele: any) => {
        if (ele.isCorrectAnswer) {
          correctAnswerTotal += +ele.scoringPoints;
        } else {
          unCorrectAnswerTotal += +ele.scoringPoints
            .toString()
            .replace('-', '');
          parseInt(unCorrectAnswerTotal);
        }
        ////////////
      });
      const formLoginControl = this.getQuestion(questionIndex, 'totalPoints');
      formLoginControl.setValue(correctAnswerTotal);
      formLoginControl.updateValueAndValidity();
    }
  }

  checkCorrectAnswer(questionIndex, optionIndex) {
    let isCorrectAnswer = this.getOption(
      questionIndex,
      optionIndex,
      'isCorrectAnswer'
    ).value;
    let scoringPoints = this.getOption(
      questionIndex,
      optionIndex,
      'scoringPoints'
    ).value;
    if (!isCorrectAnswer && scoringPoints > 0) {
      let value = `-${scoringPoints}`;
      this.getOption(questionIndex, optionIndex, 'scoringPoints').setValue(
        value
      );
    }
  }

  checkDisabled(question, option, questionIndex, optionIndex) {
    if (
      this.questionsArray.controls[questionIndex].get('questionType').value
        ?.id == QuizQuestionType.MULTIPLECHOICE
    ) {
      question.controls.forEach((element, index) => {
        if (optionIndex !== index) {
          element.controls.isCorrectAnswer.setValue(false);
        }
      });
    }
  }

  changeNegativeValue(questionIndex, optionIndex) {
    let scoringPoints = this.getOption(
      questionIndex,
      optionIndex,
      'scoringPoints'
    ).value;
    let isCorrectAnswer = this.getOption(
      questionIndex,
      optionIndex,
      'isCorrectAnswer'
    ).value;
    if (
      this.questionsArray.controls[questionIndex].get('questionType').value
        ?.id == QuizQuestionType.CKECKBOX &&
      this.getOption(questionIndex, optionIndex, 'isCorrectAnswer').value
    ) {
      scoringPoints = scoringPoints.toString().replace('-', '');
      console.log(scoringPoints);
      this.getOption(questionIndex, optionIndex, 'scoringPoints').setValue(
        scoringPoints
      );
    } else {
      if (!isCorrectAnswer && scoringPoints > 0) {
        let value = `-${scoringPoints}`;
        this.getOption(questionIndex, optionIndex, 'scoringPoints').setValue(
          value
        );
      }
    }
  }

  toggleProcedure(index: any) {
    if (this.activeIndex === index) {
      this.activeIndex = undefined;
    } else {
      this.activeIndex = index;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    console.clear();
    console.log(event);
    moveItemInArray(
      this.questionsArray.controls,
      event.previousIndex,
      event.currentIndex
    );
    this.questionsArray.updateValueAndValidity();
    if (
      this.isExpandQustion[event.previousIndex] &&
      !this.isExpandQustion[event.currentIndex]
    ) {
      this.isExpandQustion[event.currentIndex] = true;
      this.isExpandQustion[event.previousIndex] = false;
    } else if (
      !this.isExpandQustion[event.previousIndex] &&
      this.isExpandQustion[event.currentIndex]
    ) {
      this.isExpandQustion[event.currentIndex] = false;
      this.isExpandQustion[event.previousIndex] = true;
    } else if (
      this.isExpandQustion[event.previousIndex] &&
      this.isExpandQustion[event.currentIndex]
    ) {
      this.isExpandQustion[event.currentIndex] = true;
      this.isExpandQustion[event.previousIndex] = true;
    }
    this.questionsArray.controls.forEach(
      (element: FormGroup, index: number) => {
        element.controls['order'].setValue(index);
      }
    );

    if (this.isQuestionBank) {
      let list = this.questionsArray.value.map((ele, index) => {
        return { id: ele.id, order: index };
      });
      this.submitOrdering(list);
    }
  }

  submitOrdering(list: any) {
    this.questionBankService.orderingQuestions(list).subscribe(
      (data: any) => {
        this.swalService.swalSuccess();
      },
      (error) => { }
    );
  }

  dropOptions(event: CdkDragDrop<string[]>, questionIndex) {
    moveItemInArray(
      this.questionvalues(questionIndex).controls,
      event.previousIndex,
      event.currentIndex
    );
    this.questionvalues(questionIndex).updateValueAndValidity();
  }

  onDragStart() {
    const bodyElement = document.body;
    bodyElement.classList.add('darg-ds');
  }

  // Function to handle drag end event
  onDragEnd(index: number) {
    const bodyElement = document.body;
    bodyElement.classList.remove('darg-ds');

    if (
      this.questionsArray.controls[index]?.value?.RandomizingLinkedOrder != null
    ) {
      (this.questionsArray.controls[index] as FormGroup).controls[
        'RandomizingLinkedOrder'
      ].setValue(null);
    }
    if (
      this.questionsArray.controls[index + 1]?.value?.RandomizingLinkedOrder !=
      null
    ) {
      (this.questionsArray.controls[index + 1] as FormGroup).controls[
        'RandomizingLinkedOrder'
      ].setValue(null);
    }
    // if (
    //   this.questionsArray.controls[index - 1]?.value?.RandomizingLinkedOrder !=
    //   null
    // ) {
    //   (this.questionsArray.controls[index - 1] as FormGroup).controls[
    //     'RandomizingLinkedOrder'
    //   ].setValue(null);
    // }
  }

  expandQuestions(index) {
    this.isExpandQustion[index] = !this.isExpandQustion[index];
  }

  showOptions(questionIndex) {
    if (this.getQuestion(questionIndex, 'questionText').value) {
      if (
        this.getQuestion(questionIndex, 'questionType').value?.id !=
        QuizQuestionType.MULTIPLECHOICE &&
        this.getQuestion(questionIndex, 'questionType').value?.id !=
        QuizQuestionType.CKECKBOX
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  totalPointsValdation(questionIndex) {
    if (
      this.getQuestion(questionIndex, 'questionType').value?.id ==
      QuizQuestionType.MULTIPLECHOICE ||
      this.getQuestion(questionIndex, 'questionType').value?.id ==
      QuizQuestionType.CKECKBOX ||
      this.getQuestion(questionIndex, 'questionType').value?.id ==
      QuizQuestionType.TEXT
    ) {
      return true;
    } else {
      return false;
    }
  }

  questionTypeChange(e, questionIndex) {
    // console.log(this.questionsArray.controls[questionIndex])
    const group = this.questionsArray.controls[questionIndex] as FormGroup;
    const questionType = (group as FormGroup).controls['questionType'];
    (group as FormGroup).controls['totalPoints'].setValue(0, {
      emitEvent: false,
    });
    if (questionType && questionType.value) {
      const options = (group as FormGroup).controls['options'] as FormArray;
      if (questionType.value.id === QuizQuestionType.MULTIPLECHOICE) {
        if (options.controls.length) {
          options.controls.forEach((element: FormGroup) => {
            element.controls.isCorrectAnswer.setValue(false, {
              emitEvent: false,
            });
            element.controls.scoringPoints.setValue(0, { emitEvent: false });
          });
        }
      }
      if (questionType.value.id == QuizQuestionType.TEXT) {
        options.clear();
      } else {
        options.clear();
        this.addQuestionValue(questionIndex);
      }
    }
  }

  validateControls(): ValidatorFn {
    return (formArray: FormArray): { [key: string]: any } | null => {
      formArray.controls.forEach((group) => {
        const questionType = (group as FormGroup).controls['questionType'];
        const formLoginControl = (group as FormGroup).controls['totalPoints'];
        const formPenaltyControl = (group as FormGroup).controls['penalty'];
        if (questionType && questionType.value) {
          const options = (group as FormArray).controls['options'];
          if (
            questionType.value.id === QuizQuestionType.CKECKBOX ||
            questionType.value.id === QuizQuestionType.MULTIPLECHOICE
          ) {
            if (
              options.controls.length &&
              !options.controls.some(
                (option) => option.controls.isCorrectAnswer.value === true
              )
            ) {
              options.setErrors({ required: true });
            }
          }
          if (
            questionType.value.id === QuizQuestionType.CKECKBOX &&
            !this.isQuestionBank
          ) {
            if (options.controls.length) {
              options.controls.forEach((element) => {
                if (element.controls.scoringPoints.value) {
                  let scoringPoints = element.controls.scoringPoints.value
                    .toString()
                    .replace('-', '');
                  if (
                    !element.controls.isCorrectAnswer.value &&
                    element.controls.scoringPoints.value != 0 &&
                    !element.controls.scoringPoints.value
                      .toString()
                      .includes('-')
                  ) {
                    element.controls.scoringPoints.setValue(
                      `-${scoringPoints}`,
                      { emitEvent: false }
                    );
                  } else if (
                    element.controls.isCorrectAnswer.value &&
                    element.controls.scoringPoints.value
                      .toString()
                      .includes('-')
                  ) {
                    element.controls.scoringPoints.setValue(scoringPoints, {
                      emitEvent: false,
                    });
                  }
                }
              });
            }

            let correctAnswerTotal: any = 0;
            let unCorrectAnswerTotal: any = 0;

            options.controls.forEach((ele: any) => {
              if (ele.controls.isCorrectAnswer.value) {
                correctAnswerTotal += +ele.controls.scoringPoints.value;
                if (ele.controls.scoringPoints.value <= 0) {
                  ele.controls.scoringPoints.setErrors({ required: true });
                }
              } else {
                ele.controls.scoringPoints.setErrors(null, {
                  emitEvent: false,
                });
                unCorrectAnswerTotal += +ele.controls.scoringPoints.value
                  .toString()
                  .replace('-', '');
                parseInt(unCorrectAnswerTotal);
              }
              if (formPenaltyControl.value) {
                if (ele.controls.scoringPoints.value == 0) {
                  ele.controls.scoringPoints.setErrors({ required: true });
                }
              }
              ////////////
            });
            if (unCorrectAnswerTotal > correctAnswerTotal) {
              formLoginControl.setErrors({ max: correctAnswerTotal });
            } else {
              formLoginControl.setErrors(null);
            }
          }
        }
      });
      return null;
    };
  }

  saveQuestion(questionIndex) {
    if (this.questionsArray.controls[questionIndex].valid) {
      if (this.isQuestionBank) {
        let data = this.questionsArray.controls[questionIndex].value
        data.order = questionIndex
        this.QuestionSaved.emit(
          data
        );
        return;
      }
      this.toggleProcedure(questionIndex);
      this.isExpandQustion[questionIndex] = false;
    } else {
      this.questionsArray.controls[questionIndex].markAllAsTouched();
    }
  }

  // Tags Methods [Start]

  getTagsList(questionIndex: number) {
    const _FormGroup: FormGroup = this.questionsArray.controls[
      questionIndex
    ] as FormGroup;
    return _FormGroup.get('QuestionTags').value;
  }
  add(event: MatChipInputEvent, questionIndex: number): void {
    const _FormGroup: FormGroup = this.questionsArray.controls[
      questionIndex
    ] as FormGroup;

    const input = event.input;
    const value: any = event.value ? event.value.trim() : ''; // Trim the value to remove any surrounding whitespace

    if (value) {
      // Check if the value is not an empty string after trimming
      const itemFound: any = this.filteredtags(questionIndex).find(
        (item: any) => value === item.name
      );

      if (itemFound) {
        this.selected(itemFound, true, questionIndex);
        return;
      }

      // Add the tag if it's not already in the tags array
      const tagFound = _FormGroup.controls.QuestionTags.value.find(
        (item: any) => value === item.name
      );
      if (!tagFound) {
        // this.tags.push({ id: null, name: value });s
        let newValue = _FormGroup.controls.QuestionTags.value;
        newValue.push({ id: null, name: value });
        _FormGroup.controls.QuestionTags.setValue(newValue);
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    // this._formGroup.controls.tags.setValue(null);
    // console.log(this.tags);
  }

  remove(tags: Lookup, questionIndex: number): void {
    const _TagsControler: FormControl = (
      this.questionsArray.controls[questionIndex] as FormGroup
    ).controls.QuestionTags as FormControl;
    let _tagsList = _TagsControler.value;
    const index = _tagsList.indexOf(tags);

    if (index >= 0) {
      _tagsList.splice(index, 1);
      (
        this.questionsArray.controls[questionIndex] as FormGroup
      ).controls.QuestionTags.setValue(_tagsList);
    }
  }

  selected(
    event: MatAutocompleteSelectedEvent,
    isExist = false,
    questionIndex: number
  ): void {
    const _TagsControler: FormControl = (
      this.questionsArray.controls[questionIndex] as FormGroup
    ).controls.QuestionTags as FormControl;
    let item: any;
    if (isExist) {
      item = event;
    } else {
      item = event.option.value;
    }

    if (!this.isInArray(_TagsControler.value, item)) {
      let newValue = (this.questionsArray.controls[questionIndex] as FormGroup)
        .controls.QuestionTags.value;
      newValue.push({ id: item.id, name: item.name });

      (
        this.questionsArray.controls[questionIndex] as FormGroup
      ).controls.QuestionTags.setValue(newValue);
      this.tagsInput.nativeElement.value = '';
      // (this.questionsArray.controls[questionIndex] as FormGroup).controls.tags.setValue(this.tags)
    }
  }

  filteredtags(questionIndex: number = 0): any[] {
    const _FormGroup: FormGroup = this.questionsArray.controls[
      questionIndex
    ] as FormGroup;
    return this.alltags.map((item) => {
      if (item.name) {
        item.hidden =
          this.isInArray(_FormGroup.controls.QuestionTags.value, item) ||
          (!item.name
            .toLowerCase()
            .includes(
              String(_FormGroup.controls.QuestionTags.value).toLowerCase()
            ) &&
            _FormGroup.controls.QuestionTags.value);
      }
      return item;
    });
  }

  isInArray(parentObj: any, item: any) {
    return parentObj.some((obj: any) => obj.id === item.id);
  }

  // Tags Methods [End]

  // Attachment Methods [Start]

  OnSelectFiles(files: any, Index: number) {
    const _FormGroup: FormGroup = this.questionsArray.controls[
      Index
    ] as FormGroup;
    _FormGroup.controls['files'].setValue(files);
  }

  DeletedFiles(files: any, index: number) {
    const _FormGroup: FormGroup = this.questionsArray.controls[
      index
    ] as FormGroup;
    _FormGroup.controls['deteledFiles'].setValue(files);
  }

  // Attachment Methods [End]

  OnClickAddQuestion() {
    if (!this.isQuestionBank || this._formGroup.valid) {
      this.addQuestion();
      this.isExpandQustion[this.activeIndex] = true;
      setTimeout(() => {
        this.scrollToFirstInvalidControl()
      }, 1000);

      if (this.activeIndex > 0 && this.isQuestionBank) {
        this.getMyTagsLookUp();
      }
    } else {
      this._formGroup.markAllAsTouched();
    }
  }
  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.questions-list .set-item.opened'
    );
    firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }

  OnLinkOrder(index: number) {
    (this.questionsArray.controls[index] as FormGroup).controls[
      'RandomizingLinkedOrder'
    ].setValue(this.isLinked(index) ? null : Number(this.questionsArray.controls[index].value.order) - 1);
    console.clear();
    console.log(this.questionsArray.value);
  }

  isLinked(index: number) {
    return this.questionsArray.controls[index].value.RandomizingLinkedOrder ==
      null
      ? false
      : true;
  }

  getNextLetter(num): String {
    var code: number = 'abcdefghijklmnopqrstuvwxyz'.charCodeAt(num);
    return String.fromCharCode(code);
  }

  hasError(controlName: string, errorName: string) {
    return this._formGroup.controls[controlName].hasError(errorName);
  }
  hasArrayError(_formGroup: any, controlName: string, errorName: string) {
    return _formGroup.get(controlName).hasError(errorName);
  }
  hasOptionError(_formGroup: any, controlName: string, errorName: string) {
    return _formGroup.controls[controlName].hasError(errorName);
  }
}
