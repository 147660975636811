<router-outlet></router-outlet>
<div id="accessibility-menu"></div>

<div class="textToSpeech" cdkDrag [class.active]="isTextToSpeechActive" [matTooltip]="'assignment.TextToSpeech' | translate"
  (click)="textToSpeech($event)">
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <path id="ic_text_to_speech"
      d="M106.28-812.741a1.525,1.525,0,0,1-1.14-.474,1.62,1.62,0,0,1-.46-1.174,1.915,1.915,0,0,1,.12-.659,1.485,1.485,0,0,1,.32-.536,13.221,13.221,0,0,0,2.7-4.079,12.722,12.722,0,0,0,.98-5.026,12.767,12.767,0,0,0-.98-5.006,13.154,13.154,0,0,0-2.7-4.1,1.487,1.487,0,0,1-.32-.536,1.916,1.916,0,0,1-.12-.659,1.62,1.62,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474,1.415,1.415,0,0,1,.62.144,1.735,1.735,0,0,1,.5.35,16.3,16.3,0,0,1,3.38,5.17,16.254,16.254,0,0,1,1.22,6.283,16.209,16.209,0,0,1-1.22,6.3,16.358,16.358,0,0,1-3.38,5.15,1.735,1.735,0,0,1-.5.35A1.415,1.415,0,0,1,106.28-812.741Zm-4.56-4.7a1.525,1.525,0,0,1-1.14-.474,1.62,1.62,0,0,1-.46-1.174,1.531,1.531,0,0,1,.14-.639,1.792,1.792,0,0,1,.34-.515,6.456,6.456,0,0,0,1.32-2,6.218,6.218,0,0,0,.48-2.451,6,6,0,0,0-.5-2.451,7.2,7.2,0,0,0-1.3-2,1.274,1.274,0,0,1-.36-.515,1.749,1.749,0,0,1-.12-.639,1.62,1.62,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474,1.765,1.765,0,0,1,.64.124,1.219,1.219,0,0,1,.52.371,9.662,9.662,0,0,1,2,3.049,9.554,9.554,0,0,1,.72,3.708,9.554,9.554,0,0,1-.72,3.708,9.661,9.661,0,0,1-2,3.049,1.217,1.217,0,0,1-.52.371A1.765,1.765,0,0,1,101.72-817.437ZM89.6-824.771H86.4v.453a3.977,3.977,0,0,0,.86,2.534,4.137,4.137,0,0,0,2.18,1.463l.48.124a2.237,2.237,0,0,1,1.8,2.06,2.313,2.313,0,0,1-1.24,2.472,15.621,15.621,0,0,1-4.28,1.73,25.472,25.472,0,0,1-4.6.659,1.4,1.4,0,0,1-1.14-.432,1.62,1.62,0,0,1-.46-1.174,1.619,1.619,0,0,1,.46-1.174,1.675,1.675,0,0,1,1.14-.515,21.111,21.111,0,0,0,2.82-.35,18.094,18.094,0,0,0,2.74-.762,7.067,7.067,0,0,1-2.9-2.74,7.512,7.512,0,0,1-1.06-3.893v-2.1a1.62,1.62,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474h4.8v-3.3a1.619,1.619,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474H95l-4.52-9.311a1.539,1.539,0,0,1-.1-1.256,1.7,1.7,0,0,1,.82-.968,1.417,1.417,0,0,1,1.22-.1,1.685,1.685,0,0,1,.94.845l4.52,9.311a3.218,3.218,0,0,1-.12,3.214,2.967,2.967,0,0,1-2.72,1.565H92.8v1.648a3.222,3.222,0,0,1-.94,2.328A3.037,3.037,0,0,1,89.6-824.771Z"
      transform="translate(-80 844.741)" fill="#5d6d7e" />
  </svg>
</div>
<!-- <app-accessibility-menu (isTextToSpeechActive)="isTextToSpeechActive = $event" [selectedText]="selectedText"></app-accessibility-menu> -->
