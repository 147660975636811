
<div class="textToSpeech" (click)="textToSpeech($event)">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
      <g id="Icons_Area" data-name="Icons Area" transform="translate(-450 -352)">
        <rect id="Container_Icons" data-name="Container / Icons" width="70" height="70" rx="10"
          transform="translate(450 352)" fill="#ebedef" />
        <path id="ic_text_to_speech"
          d="M106.28-812.741a1.525,1.525,0,0,1-1.14-.474,1.62,1.62,0,0,1-.46-1.174,1.915,1.915,0,0,1,.12-.659,1.485,1.485,0,0,1,.32-.536,13.221,13.221,0,0,0,2.7-4.079,12.722,12.722,0,0,0,.98-5.026,12.767,12.767,0,0,0-.98-5.006,13.154,13.154,0,0,0-2.7-4.1,1.487,1.487,0,0,1-.32-.536,1.916,1.916,0,0,1-.12-.659,1.62,1.62,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474,1.415,1.415,0,0,1,.62.144,1.735,1.735,0,0,1,.5.35,16.3,16.3,0,0,1,3.38,5.17,16.254,16.254,0,0,1,1.22,6.283,16.209,16.209,0,0,1-1.22,6.3,16.358,16.358,0,0,1-3.38,5.15,1.735,1.735,0,0,1-.5.35A1.415,1.415,0,0,1,106.28-812.741Zm-4.56-4.7a1.525,1.525,0,0,1-1.14-.474,1.62,1.62,0,0,1-.46-1.174,1.531,1.531,0,0,1,.14-.639,1.792,1.792,0,0,1,.34-.515,6.456,6.456,0,0,0,1.32-2,6.218,6.218,0,0,0,.48-2.451,6,6,0,0,0-.5-2.451,7.2,7.2,0,0,0-1.3-2,1.274,1.274,0,0,1-.36-.515,1.749,1.749,0,0,1-.12-.639,1.62,1.62,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474,1.765,1.765,0,0,1,.64.124,1.219,1.219,0,0,1,.52.371,9.662,9.662,0,0,1,2,3.049,9.554,9.554,0,0,1,.72,3.708,9.554,9.554,0,0,1-.72,3.708,9.661,9.661,0,0,1-2,3.049,1.217,1.217,0,0,1-.52.371A1.765,1.765,0,0,1,101.72-817.437ZM89.6-824.771H86.4v.453a3.977,3.977,0,0,0,.86,2.534,4.137,4.137,0,0,0,2.18,1.463l.48.124a2.237,2.237,0,0,1,1.8,2.06,2.313,2.313,0,0,1-1.24,2.472,15.621,15.621,0,0,1-4.28,1.73,25.472,25.472,0,0,1-4.6.659,1.4,1.4,0,0,1-1.14-.432,1.62,1.62,0,0,1-.46-1.174,1.619,1.619,0,0,1,.46-1.174,1.675,1.675,0,0,1,1.14-.515,21.111,21.111,0,0,0,2.82-.35,18.094,18.094,0,0,0,2.74-.762,7.067,7.067,0,0,1-2.9-2.74,7.512,7.512,0,0,1-1.06-3.893v-2.1a1.62,1.62,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474h4.8v-3.3a1.619,1.619,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474H95l-4.52-9.311a1.539,1.539,0,0,1-.1-1.256,1.7,1.7,0,0,1,.82-.968,1.417,1.417,0,0,1,1.22-.1,1.685,1.685,0,0,1,.94.845l4.52,9.311a3.218,3.218,0,0,1-.12,3.214,2.967,2.967,0,0,1-2.72,1.565H92.8v1.648a3.222,3.222,0,0,1-.94,2.328A3.037,3.037,0,0,1,89.6-824.771Z"
          transform="translate(389 1214.741)" fill="#643676" />
      </g>
    </svg>
    <span>Text to speech</span>
  </div>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="27.001" viewBox="0 0 34 27.001">
      <path id="ic_speech_speed"
        d="M-2128.657-4626h-24.686a2.812,2.812,0,0,1-1.365-.342,2.668,2.668,0,0,1-1.011-.988,18.083,18.083,0,0,1-1.7-4.136,17.009,17.009,0,0,1-.584-4.434,16.794,16.794,0,0,1,1.341-6.669,17.329,17.329,0,0,1,3.636-5.429,17.244,17.244,0,0,1,5.4-3.656,16.577,16.577,0,0,1,6.635-1.347,16.477,16.477,0,0,1,6.569,1.326,17.314,17.314,0,0,1,5.375,3.612,17.453,17.453,0,0,1,3.658,5.35,16.455,16.455,0,0,1,1.383,6.574,17.738,17.738,0,0,1-.542,4.59,17.46,17.46,0,0,1-1.738,4.288,2.323,2.323,0,0,1-1,.954A3.119,3.119,0,0,1-2128.657-4626Zm-12.153-20.015h0a.755.755,0,0,0-.806.685l-2.291,12.6a2.741,2.741,0,0,0,.725,2.342,3.026,3.026,0,0,0,2.355,1.119h.013a3.143,3.143,0,0,0,2.382-1.112,2.643,2.643,0,0,0,.789-2.328l-2.351-12.614a.767.767,0,0,0-.814-.689Z"
        transform="translate(2158 4653)" fill="#9aa4af" />
    </svg>
  </div>
</div>

<!-- <div class="accessibility-menu" id="accessibilityMenu" [class.invertColorsActive]="invertColorsActive">
  <div class="menu" *ngIf="openMenu">
    <div class="menu-header">
      <h4>Accessibility Menu</h4>
      <div class="head-actions">
        <a class="reset" (click)="reset()" href="javascript:void(0)">
          <svg id="Resert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
            <circle id="Circle" cx="15" cy="15" r="15" fill="#c92d7d" />
            <path id="ic_reset"
              d="M164.733-829.03a6.39,6.39,0,0,1-3.412-2.23,5.967,5.967,0,0,1-1.32-3.822,5.931,5.931,0,0,1,.386-2.132,6.42,6.42,0,0,1,1.1-1.857.76.76,0,0,1,.548-.246.792.792,0,0,1,.589.246.745.745,0,0,1,.234.531.871.871,0,0,1-.213.589,4.685,4.685,0,0,0-.752,1.336,4.454,4.454,0,0,0-.264,1.533,4.518,4.518,0,0,0,.965,2.839,4.792,4.792,0,0,0,2.488,1.7.86.86,0,0,1,.437.295.739.739,0,0,1,.173.471.748.748,0,0,1-.284.619A.732.732,0,0,1,164.733-829.03Zm3.534,0a.717.717,0,0,1-.67-.138.775.775,0,0,1-.284-.629.715.715,0,0,1,.173-.452.86.86,0,0,1,.437-.295,4.926,4.926,0,0,0,2.488-1.709,4.478,4.478,0,0,0,.965-2.829,4.474,4.474,0,0,0-1.422-3.34A4.783,4.783,0,0,0,166.5-839.8h-.061l.325.314a.732.732,0,0,1,.223.55.732.732,0,0,1-.223.55.784.784,0,0,1-.569.216.785.785,0,0,1-.569-.216l-1.706-1.65a.688.688,0,0,1-.173-.255.845.845,0,0,1-.051-.295.844.844,0,0,1,.051-.295.688.688,0,0,1,.173-.255l1.706-1.65A.784.784,0,0,1,166.2-843a.784.784,0,0,1,.569.216.733.733,0,0,1,.223.55.732.732,0,0,1-.223.55l-.325.314h.061a6.383,6.383,0,0,1,4.611,1.827,5.969,5.969,0,0,1,1.889,4.46,5.979,5.979,0,0,1-1.32,3.812A6.369,6.369,0,0,1,168.267-829.03Z"
              transform="translate(-151.5 851)" fill="#fcfbfd" />
          </svg>
        </a>
        <a (click)="openMenu = !openMenu" href="javascript:void(0)">
          <svg id="Close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
            <circle id="Circle" cx="15" cy="15" r="15" fill="#b00d61" />
            <path id="ic_close"
              d="M15,6.007,13.993,5,10,8.993,6.007,5,5,6.007,8.993,10,5,13.993,6.007,15,10,11.007,13.993,15,15,13.993,11.007,10Z"
              transform="translate(5 5)" fill="#fcfbfd" />
          </svg>
        </a>
      </div>
    </div>
    <div class="menu-body">
      <ul>
        <li>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
              <g id="Icons_Area" data-name="Icons Area" transform="translate(-450 -352)">
                <rect id="Container_Icons" data-name="Container / Icons" width="70" height="70" rx="10"
                  transform="translate(450 352)" fill="#ebedef" />
                <path id="ic_text_size"
                  d="M99.2-795.125H93.6a2.3,2.3,0,0,1-1.7-.711,2.369,2.369,0,0,1-.7-1.727,2.369,2.369,0,0,1,.7-1.727A2.3,2.3,0,0,1,93.6-800h16a2.3,2.3,0,0,1,1.7.711,2.369,2.369,0,0,1,.7,1.727,2.369,2.369,0,0,1-.7,1.727,2.3,2.3,0,0,1-1.7.711H104v18.688a2.369,2.369,0,0,1-.7,1.727,2.3,2.3,0,0,1-1.7.711,2.3,2.3,0,0,1-1.7-.711,2.369,2.369,0,0,1-.7-1.727ZM84.8-787H82.4a2.3,2.3,0,0,1-1.7-.711,2.369,2.369,0,0,1-.7-1.727,2.369,2.369,0,0,1,.7-1.727,2.3,2.3,0,0,1,1.7-.711H92a2.3,2.3,0,0,1,1.7.711,2.369,2.369,0,0,1,.7,1.727,2.369,2.369,0,0,1-.7,1.727A2.3,2.3,0,0,1,92-787H89.6v10.563a2.369,2.369,0,0,1-.7,1.727,2.3,2.3,0,0,1-1.7.711,2.3,2.3,0,0,1-1.7-.711,2.369,2.369,0,0,1-.7-1.727Z"
                  transform="translate(389 1174)" fill="#643676" />
              </g>
            </svg>
            <span>Text Size</span>
          </div>
          <div class="actions">
            <button (click)="decreaseFontSize()">
              <svg id="Decrease_Size" data-name="Decrease Size" xmlns="http://www.w3.org/2000/svg" width="34"
                height="34" viewBox="0 0 34 34">
                <circle id="Circle" cx="17" cy="17" r="17" fill="#2a3a4b" />
                <rect id="ic_minus" width="16" height="3" rx="1.5" transform="translate(9 15.5)" fill="#fff" />
              </svg>
            </button>
            <button (click)="increaseFontSize()">
              <svg id="Increase_Size" data-name="Increase Size" xmlns="http://www.w3.org/2000/svg" width="34"
                height="34" viewBox="0 0 34 34">
                <circle id="Circle" cx="17" cy="17" r="17" fill="#2a3a4b" />
                <path id="ic_plus"
                  d="M206.857-750.857h-5.714a1.106,1.106,0,0,1-.814-.329A1.106,1.106,0,0,1,200-752a1.106,1.106,0,0,1,.329-.814,1.106,1.106,0,0,1,.814-.329h5.714v-5.714a1.106,1.106,0,0,1,.329-.814A1.106,1.106,0,0,1,208-760a1.106,1.106,0,0,1,.814.329,1.106,1.106,0,0,1,.329.814v5.714h5.714a1.106,1.106,0,0,1,.814.329A1.106,1.106,0,0,1,216-752a1.106,1.106,0,0,1-.329.814,1.106,1.106,0,0,1-.814.329h-5.714v5.714a1.106,1.106,0,0,1-.329.814A1.106,1.106,0,0,1,208-744a1.106,1.106,0,0,1-.814-.329,1.106,1.106,0,0,1-.329-.814Z"
                  transform="translate(-191 769)" fill="#fff" />
              </svg>
            </button>
          </div>
        </li>
        <li class="link-item" (click)="textToSpeech($event)">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
              <g id="Icons_Area" data-name="Icons Area" transform="translate(-450 -352)">
                <rect id="Container_Icons" data-name="Container / Icons" width="70" height="70" rx="10"
                  transform="translate(450 352)" fill="#ebedef" />
                <path id="ic_text_to_speech"
                  d="M106.28-812.741a1.525,1.525,0,0,1-1.14-.474,1.62,1.62,0,0,1-.46-1.174,1.915,1.915,0,0,1,.12-.659,1.485,1.485,0,0,1,.32-.536,13.221,13.221,0,0,0,2.7-4.079,12.722,12.722,0,0,0,.98-5.026,12.767,12.767,0,0,0-.98-5.006,13.154,13.154,0,0,0-2.7-4.1,1.487,1.487,0,0,1-.32-.536,1.916,1.916,0,0,1-.12-.659,1.62,1.62,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474,1.415,1.415,0,0,1,.62.144,1.735,1.735,0,0,1,.5.35,16.3,16.3,0,0,1,3.38,5.17,16.254,16.254,0,0,1,1.22,6.283,16.209,16.209,0,0,1-1.22,6.3,16.358,16.358,0,0,1-3.38,5.15,1.735,1.735,0,0,1-.5.35A1.415,1.415,0,0,1,106.28-812.741Zm-4.56-4.7a1.525,1.525,0,0,1-1.14-.474,1.62,1.62,0,0,1-.46-1.174,1.531,1.531,0,0,1,.14-.639,1.792,1.792,0,0,1,.34-.515,6.456,6.456,0,0,0,1.32-2,6.218,6.218,0,0,0,.48-2.451,6,6,0,0,0-.5-2.451,7.2,7.2,0,0,0-1.3-2,1.274,1.274,0,0,1-.36-.515,1.749,1.749,0,0,1-.12-.639,1.62,1.62,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474,1.765,1.765,0,0,1,.64.124,1.219,1.219,0,0,1,.52.371,9.662,9.662,0,0,1,2,3.049,9.554,9.554,0,0,1,.72,3.708,9.554,9.554,0,0,1-.72,3.708,9.661,9.661,0,0,1-2,3.049,1.217,1.217,0,0,1-.52.371A1.765,1.765,0,0,1,101.72-817.437ZM89.6-824.771H86.4v.453a3.977,3.977,0,0,0,.86,2.534,4.137,4.137,0,0,0,2.18,1.463l.48.124a2.237,2.237,0,0,1,1.8,2.06,2.313,2.313,0,0,1-1.24,2.472,15.621,15.621,0,0,1-4.28,1.73,25.472,25.472,0,0,1-4.6.659,1.4,1.4,0,0,1-1.14-.432,1.62,1.62,0,0,1-.46-1.174,1.619,1.619,0,0,1,.46-1.174,1.675,1.675,0,0,1,1.14-.515,21.111,21.111,0,0,0,2.82-.35,18.094,18.094,0,0,0,2.74-.762,7.067,7.067,0,0,1-2.9-2.74,7.512,7.512,0,0,1-1.06-3.893v-2.1a1.62,1.62,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474h4.8v-3.3a1.619,1.619,0,0,1,.46-1.174,1.525,1.525,0,0,1,1.14-.474H95l-4.52-9.311a1.539,1.539,0,0,1-.1-1.256,1.7,1.7,0,0,1,.82-.968,1.417,1.417,0,0,1,1.22-.1,1.685,1.685,0,0,1,.94.845l4.52,9.311a3.218,3.218,0,0,1-.12,3.214,2.967,2.967,0,0,1-2.72,1.565H92.8v1.648a3.222,3.222,0,0,1-.94,2.328A3.037,3.037,0,0,1,89.6-824.771Z"
                  transform="translate(389 1214.741)" fill="#643676" />
              </g>
            </svg>
            <span>Text to speech</span>
          </div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="27.001" viewBox="0 0 34 27.001">
              <path id="ic_speech_speed"
                d="M-2128.657-4626h-24.686a2.812,2.812,0,0,1-1.365-.342,2.668,2.668,0,0,1-1.011-.988,18.083,18.083,0,0,1-1.7-4.136,17.009,17.009,0,0,1-.584-4.434,16.794,16.794,0,0,1,1.341-6.669,17.329,17.329,0,0,1,3.636-5.429,17.244,17.244,0,0,1,5.4-3.656,16.577,16.577,0,0,1,6.635-1.347,16.477,16.477,0,0,1,6.569,1.326,17.314,17.314,0,0,1,5.375,3.612,17.453,17.453,0,0,1,3.658,5.35,16.455,16.455,0,0,1,1.383,6.574,17.738,17.738,0,0,1-.542,4.59,17.46,17.46,0,0,1-1.738,4.288,2.323,2.323,0,0,1-1,.954A3.119,3.119,0,0,1-2128.657-4626Zm-12.153-20.015h0a.755.755,0,0,0-.806.685l-2.291,12.6a2.741,2.741,0,0,0,.725,2.342,3.026,3.026,0,0,0,2.355,1.119h.013a3.143,3.143,0,0,0,2.382-1.112,2.643,2.643,0,0,0,.789-2.328l-2.351-12.614a.767.767,0,0,0-.814-.689Z"
                transform="translate(2158 4653)" fill="#9aa4af" />
            </svg>
          </div>
        </li>
        <li class="link-item" (click)="invertColors()">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
              <g id="Icons_Area" data-name="Icons Area" transform="translate(-450 -352)">
                <rect id="Container_Icons" data-name="Container / Icons" width="70" height="70" rx="10"
                  transform="translate(450 352)" fill="#ebedef" />
                <path id="ic_inver_colors"
                  d="M174-824a13.652,13.652,0,0,1-9.909-4A13.058,13.058,0,0,1,160-837.7a12.945,12.945,0,0,1,1.094-5.326,14.329,14.329,0,0,1,3.019-4.37l8.05-7.87a3.047,3.047,0,0,1,.875-.544A2.541,2.541,0,0,1,174-856a2.541,2.541,0,0,1,.962.2,3.047,3.047,0,0,1,.875.544l8.05,7.87a14.329,14.329,0,0,1,3.019,4.37A12.945,12.945,0,0,1,188-837.7a13.058,13.058,0,0,1-4.091,9.7A13.652,13.652,0,0,1,174-824Zm0-3.478v-24.7l-7.437,7.3a9.448,9.448,0,0,0-2.3,3.261,10.017,10.017,0,0,0-.766,3.913,9.74,9.74,0,0,0,3.063,7.217A10.234,10.234,0,0,0,174-827.478Z"
                  transform="translate(311 1226)" fill="#643676" />
              </g>
            </svg>
            <span>Invert Colors</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <a class="openMenu" (click)="openMenu = !openMenu" cdkDrag (cdkDragStarted)="onDragStarted()">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="116" height="116"
      viewBox="0 0 116 116">
      <defs>
        <linearGradient id="linear-gradient" x1="0.243" y1="0.067" x2="0.844" y2="0.874"
          gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#c92d7d" />
          <stop offset="1" stop-color="#a40054" />
        </linearGradient>
        <filter id="circle" x="0" y="0" width="116" height="116" filterUnits="userSpaceOnUse">
          <feOffset dx="1" dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="6" result="blur" />
          <feFlood flood-color="#c92d7d" flood-opacity="0.502" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Accessibility_FAB_" data-name="Accessibility [FAB]" transform="translate(17 15)">
        <g transform="matrix(1, 0, 0, 1, -17, -15)" filter="url(#circle)">
          <circle id="circle-2" data-name="circle" cx="40" cy="40" r="40" transform="translate(17 15)"
            fill="url(#linear-gradient)" />
        </g>
        <g id="Group_38738" data-name="Group 38738">
          <g id="border" transform="translate(5 5)" fill="none" stroke="#fff" stroke-width="2">
            <circle cx="35" cy="35" r="35" stroke="none" />
            <circle cx="35" cy="35" r="34" fill="none" />
          </g>
          <path id="ic_accessibility"
            d="M163.08-863.981a3.616,3.616,0,0,1-2.661-1.1,3.653,3.653,0,0,1-1.1-2.675,3.653,3.653,0,0,1,1.1-2.675,3.615,3.615,0,0,1,2.661-1.1,3.615,3.615,0,0,1,2.661,1.1,3.653,3.653,0,0,1,1.1,2.675,3.653,3.653,0,0,1-1.1,2.675A3.616,3.616,0,0,1,163.08-863.981Zm-5.287,30.906v-25.157q-2.671-.224-5.285-.583t-5.058-.921a1.862,1.862,0,0,1-1.086-.736,1.329,1.329,0,0,1-.221-1.224,1.5,1.5,0,0,1,.815-.975,1.922,1.922,0,0,1,1.32-.135,61.46,61.46,0,0,0,7.3,1.145q3.716.359,7.5.358t7.5-.358a61.46,61.46,0,0,0,7.3-1.145,1.922,1.922,0,0,1,1.32.135,1.5,1.5,0,0,1,.815.975,1.329,1.329,0,0,1-.221,1.224,1.862,1.862,0,0,1-1.086.736q-2.444.563-5.058.921t-5.285.583v25.157a1.49,1.49,0,0,1-.438,1.094,1.474,1.474,0,0,1-1.089.441,1.474,1.474,0,0,1-1.089-.441,1.49,1.49,0,0,1-.438-1.094V-844.06h-4.465v10.984a1.49,1.49,0,0,1-.438,1.094,1.474,1.474,0,0,1-1.089.441,1.474,1.474,0,0,1-1.088-.441A1.49,1.49,0,0,1,157.793-833.076Z"
            transform="translate(-123.08 891.54)" fill="#fff" />
        </g>
      </g>
    </svg>
  </a>
</div> -->
