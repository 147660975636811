<div mat-dialog-title>
  <h2 class="d-block" *ngIf="data.isShare">
    Share Slides
    <span class="ml-0 mt-1">(Select slides you want to Share to Concurel)</span>
  </h2>
  <h2 class="d-block" *ngIf="!data.isShare">
    View Multiple Slides
    <span class="ml-0 mt-1">(Select slides you want to open)</span>
  </h2>
  <button type="button" class="close" mat-dialog-close aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<mat-dialog-content class="assign-dialog-content multiple_slides" *ngIf="slidesList.length; else dataEmpty"
  infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2" [infiniteScrollThrottle]="pageCount"
  (scrolled)="getNextPage()">
  <div class="page">

    <div class="">

      <table class="table table-primary table_assign">
        <thead>
          <tr>
            <th width="1%"></th>
            <ng-container *ngIf="!data.isCompare">
              <th>Slide No.</th>
              <th>Stain type</th>
            </ng-container>
            <ng-container *ngIf="data.isCompare">
              <th width="1%"></th>
              <th>Slide Name</th>
              <th>Subspecialty</th>
              <th>Tissue Type</th>
              <th>Stain Type</th>
              <th>Patient age & gender</th>
              <th>Tags</th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let record of slidesList; let i = index">
            <tr (click)="selectedSlides(record[idKey],data.slideId ==  record[idKey] && data.isCompare)"
              [class.active]="isInArray(record[idKey])">
              <td>
                <mat-checkbox [checked]="isInArray(record[idKey])"
                  [disabled]="data.slideId == record[idKey] && data.isCompare"></mat-checkbox>
              </td>
              <ng-container *ngIf="!data.isCompare">
                <td>
                  #{{i + 1}}
                </td>
                <td>{{record.stainType.name}}</td>
              </ng-container>
              <ng-container *ngIf="data.isCompare">
                <td>
                  <div class="img-circle">
                    <div class="image m-0 rounded">
                      <img *ngIf="record.thumpnail" [src]="record.thumpnail" (error)='record.thumpnail = ""'
                        class="rounded">
                      <img *ngIf="!record.thumpnail" src="assets/images/icons8-no-image-100.png" class="rounded">
                    </div>
                  </div>
                </td>
                <td>
                  {{record.name}}
                </td>
                <td>
                  {{record.subSpecialty}}
                </td>
                <td>
                  {{record.tissueType}}
                </td>
                <td>
                  {{record.stainType}}
                </td>
                <td>{{record.age}} years/{{record.gender}}</td>
                <td [matTooltip]="joinArray(record.caseTags)">
                  <ng-container *ngFor="let item of record.caseTags; index as i">
                    <span *ngIf="i < 2">{{(i !== 0 ? ' ,' : '') + item | truncateText:30}}</span>
                  </ng-container>
                  <span *ngIf="record.caseTags.length > 2">, +{{record.caseTags.length - 2}}</span>
                </td>
              </ng-container>

            </tr>
          </ng-container>

        </tbody>

      </table>
    </div>
  </div>
</mat-dialog-content>

<div *ngIf='dataLoading' class="dataLoading">
  <mat-spinner class="m-auto" diameter="50"></mat-spinner>
</div>

<ng-template #dataEmpty>
  <ng-container *ngIf="!dataLoading">
    <app-empty-state [title]='"general.noDataFound"|translate'></app-empty-state>
  </ng-container>
</ng-template>

<mat-dialog-actions align="end" [ngClass]="data.isShare ? 'justify-content-end' : 'justify-content-between'"
  class="assign-dialog-actions">
  <p *ngIf="!data.isShare">Min 2, Max 4 slides to view</p>
  <div>
    <button mat-raised-button color="grey" mat-dialog-close [disabled]='loading'>Cancel</button>
    <ng-container *ngIf="!data.notAuthenticated">
      <a mat-raised-button color="primary" *ngIf="selected.length >= 2 && selected.length <= 4 && !data.isShare"
        (click)="continue()">
        View Slides
      </a>
      <button mat-raised-button color="primary" *ngIf="data.isShare && selected.length" [class.loading]='loading'
        [disabled]='loading' (click)="loginConcurel()">Share to Concurel</button>
    </ng-container>
    <a mat-raised-button color="primary" mat-dialog-close *ngIf="data.notAuthenticated" [routerLink]="
    ['slideListShare',data.caseIdType, data.caseId, 0]" [queryParams]="{slide: selected}">
      View Slides
    </a>
  </div>
</mat-dialog-actions>